// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appname:"Alumni Femis (Beta Test)",
  name: "local",
  domain_server:"https://femis.f80.fr:8000",
  server:"https://femis.f80.fr:8000",
  config_file:"config",
  max_file_size: 100000,
  domain_appli: "https://alumni.femis.fr",
  appVersion: require('../../package.json').version + '-dev',
  search_intro: "Rechercher des anciens de la FEMIS par nom, métier, promotion, titre de film ou récompense",
  support:{
    forum: "",
    email: "alumni@femis.fr",
    facebook: "https://www.facebook.com/LaFemis.Paris",
    faq: "./faq.html?from=appli",
    website: "https://femis.fr/"
  },

  cursus_filter:"",
  splash:{
    logo:"./assets/icons/icon-96x96.png",
    image: "./assets/img/intro1.jpg",
    intro: "L'annuaire des anciens de la FEMIS",
  },
  logo_transparent:"./assets/logo_femis_menu@2x.png",
  icon_appli:"./assets/icons/icon-72x72.png",
  search_placeholder: ["julia","duc*","ozon","2016","réalisation","scénario 2012","atelier réécriture"],
  menu: {
    header:{
      search: {label:"Annuaire",title:"Consultation de l'annuaire",icon:"people_alt",queryParam:{},actif:true},
      pows: {label:"Films & Séries",title:"Voir les oeuvres des anciens",icon:"movie",queryParam:{},actif:true},
      festivals: {label:"Récompenses",title:"Voir les festivals qui récompense les anciens de la FEMIS",icon:"emoji_events",queryParam:{},actif:false},
      blog: {label:"Actualités",title:"Voir le blog",icon:"rss_feed",queryParam:{},actif:true},
      edit: {label:"Mon profil",title:"Editer votre profil",icon:"build",queryParam:{id:"$profil"},actif:false},
      htmledit: {label:"Publier",title:"Rédiger un article",icon:"history_edu",queryParam:{},actif:false},
      addpow: {label:"Base FEMIS",title:"Ajouter des films dans le catalogue",icon:"videocam",queryParam:{owner:'public'},actif:false},
      stats: {label:"Datas",title:"Accèder à la console de statistiques",icon:"leaderboard",queryParam:{},actif:false}
    },
    footer:{
      dev:{label:"Développeurs",title:"Accèder à la console developper",icon:"build",queryParam:{},actif:false},
      settings: {label:"Mon compte",title:"Modifier vos paramètres",icon:"person",queryParam:{},actif:false},
      _logout: {label:"Se déconnecter",title:"Se déconnecter",icon:"logout",queryParam:{},actif:false},
      login: {label:"Se connecter",title:"Se connecter",icon:"login",queryParam:{},actif:true},
      nfts: {label:"Certification",title:"Voir les certifications NFTs",icon:"shield",queryParam:{},actif:false},
      faqs: {label:"Aide",title:"Aide / Questions fréquentes",icon:"help",queryParam:{},actif:true},
      about: {label:"A propos",title:"A propos de Data Culture",icon:"info",queryParam:{},actif:true},
      admin: {label:"Administration",title:"Administration de DataCulture",icon:"settings",queryParam:{},actif:false}
    }
  },
  link_to_news: "https://www.femis.fr/index.php?page=diplomes",
  show_prof_formation:true,
  optin_label:"Communications",
  optin_options:[
    {id:"newsletter",label:"Recevoir la newsletter FEMIS",value:false,profil:"connecte"},
    {id:"student_comm",label:"Communication dédiée aux anciens étudiants",value:false,profil:"student"},
  ],
  vitrine_title:"Parmis nos alumni",
  maintenance:"",
  backup_prefix:"femis",
  departments: undefined,
  oasis_domain: ""
};

//domain_server:"http://localhost:8000",
//domain_server:"http://192.168.1.62:8000",
//domain_server:"http://provider.bdl.computer:30648",
//domain_server:"http://192.168.1.62:8100",


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
